import { ApolloClient } from "@apollo/client";
import { DeepExtractTypeSkipArrays } from "ts-deep-extract-types";

import { paginatedRequestStreamShopifyGraphQL } from "./_utils";
import {
  DeliveryMethodDefinitionFragmentFragment,
  DeliveryProfileCreate,
  DeliveryProfileCreateMutation,
  DeliveryProfileCreateMutationVariables,
  DeliveryProfileFragmentFragment,
  DeliveryProfileInput,
  DeliveryProfileLocationGroupZoneFragmentFragment,
  DeliveryProfileRemove,
  DeliveryProfileRemoveMutation,
  DeliveryProfileRemoveMutationVariables,
  DeliveryProfileUpdate,
  DeliveryProfileUpdateMutation,
  DeliveryProfileUpdateMutationVariables,
  DeliverySettingInput,
  DeliverySettingUpdate,
  DeliverySettingUpdateMutation,
  DeliverySettingUpdateMutationVariables,
  DeliveryShippingOriginAssign,
  DeliveryShippingOriginAssignMutation,
  DeliveryShippingOriginAssignMutationVariables,
  GetDeliveryProfile,
  GetDeliveryProfileItems,
  GetDeliveryProfileItemsQuery,
  GetDeliveryProfileItemsQueryVariables,
  GetDeliveryProfileLocationGroupLocations,
  GetDeliveryProfileLocationGroupLocationsQuery,
  GetDeliveryProfileLocationGroupLocationsQueryVariables,
  GetDeliveryProfileLocationGroupZoneMethodDefinitions,
  GetDeliveryProfileLocationGroupZoneMethodDefinitionsQuery,
  GetDeliveryProfileLocationGroupZoneMethodDefinitionsQueryVariables,
  GetDeliveryProfileLocationGroupZones,
  GetDeliveryProfileLocationGroupZonesQuery,
  GetDeliveryProfileLocationGroupZonesQueryVariables,
  GetDeliveryProfileQuery,
  GetDeliveryProfileQueryVariables,
  GetDeliveryProfileSellingPlanGroups,
  GetDeliveryProfileSellingPlanGroupsQuery,
  GetDeliveryProfileSellingPlanGroupsQueryVariables,
  GetDeliveryProfiles,
  GetDeliveryProfilesQuery,
  GetDeliveryProfilesQueryVariables,
  GetLocationsAvailableForDeliveryProfilesConnection,
  GetLocationsAvailableForDeliveryProfilesConnectionQuery,
  GetLocationsAvailableForDeliveryProfilesConnectionQueryVariables,
  GetShopDeliverySettings,
  GetShopDeliverySettingsQuery,
  GetShopDeliverySettingsQueryVariables,
  LocationFragmentFragment,
} from "../shopifyGraphQL/api";

import { apolloQuery, apolloShopifyMutation, apolloShopifyQuery, throws } from ".";

export type FullDeliveryProfile = DeliveryProfileFragmentFragment & {
  sellingPlanGroups: { id: string }[];
  profileItems: { product: { id: string } }[];
  profileLocationGroups: Array<{
    locationGroupZones: (DeliveryProfileLocationGroupZoneFragmentFragment & {
      methodDefinitions: DeliveryMethodDefinitionFragmentFragment[];
    })[];
    locationGroup: {
      locations: LocationFragmentFragment[];
    };
  }>;
};
export type FullDeliveryProfileLocationGroup = FullDeliveryProfile["profileLocationGroups"][number];
export type FullDeliveryProfileLocationGroupZone = FullDeliveryProfileLocationGroup["locationGroupZones"][number];

export async function paginatedQueryLocationsAvailableForDeliveryProfilesConnection(
  client: ApolloClient<object>
) {
  const locations: LocationFragmentFragment[] = [];
  await paginatedRequestStreamShopifyGraphQL(
    cursor => queryLocationsAvailableForDeliveryProfilesConnection(client, 5, cursor),
    res => res.data.locationsAvailableForDeliveryProfilesConnection,
    async res => {
      const nodes = res.data.locationsAvailableForDeliveryProfilesConnection.edges.map(({ node }) => node) || [];
      for (const node of nodes) {
        locations.push(node);
      }
    }
  );
  return locations;
}

export function queryLocationsAvailableForDeliveryProfilesConnection(
  client: ApolloClient<object>,
  count = 1,
  cursor?: string | null
) {
  return apolloShopifyQuery<
    GetLocationsAvailableForDeliveryProfilesConnectionQuery,
    GetLocationsAvailableForDeliveryProfilesConnectionQueryVariables
  >(
    {
      query: GetLocationsAvailableForDeliveryProfilesConnection,
      variables: {
        count,
        cursor,
      },
    },
    client
  );
}

export function queryShopifyDeliveryProfiles(client: ApolloClient<object>, first = 1, cursor?: string | null) {
  return apolloQuery<GetDeliveryProfilesQuery, GetDeliveryProfilesQueryVariables>(
    {
      query: GetDeliveryProfiles,
      variables: { cursor, first },
      fetchPolicy: "no-cache",
    },
    client
  );
}

export async function paginatedQueryShopifyDeliveryProfiles(client: ApolloClient<object>) {
  const deliveryProfiles: DeepExtractTypeSkipArrays<
    GetDeliveryProfilesQuery,
    ["deliveryProfiles", "edges", "node"]
  >[] = [];
  await paginatedRequestStreamShopifyGraphQL(
    cursor => queryShopifyDeliveryProfiles(client, 5, cursor),
    res => res.data.deliveryProfiles,
    async res => {
      const nodes = res.data.deliveryProfiles.edges.map(({ node }) => node);
      for (const node of nodes) {
        deliveryProfiles.push(node);
      }
    }
  );
  return deliveryProfiles;
}

export function queryShopifyDeliveryProfile(id: string, client: ApolloClient<object>) {
  return apolloShopifyQuery<GetDeliveryProfileQuery, GetDeliveryProfileQueryVariables>(
    {
      query: GetDeliveryProfile,
      fetchPolicy: "no-cache",
      variables: {
        id,
      },
    },
    client
  );
}

export async function paginatedQueryShopifyDeliveryProfileItems(id: string, client: ApolloClient<object>) {
  const profileItems: DeepExtractTypeSkipArrays<
    GetDeliveryProfileItemsQuery,
    ["deliveryProfile", "profileItems", "edges", "node"]
  >[] = [];
  await paginatedRequestStreamShopifyGraphQL(
    cursor => queryShopifyDeliveryProfileItems(client, id, 5, cursor),
    res => res.data.deliveryProfile!.profileItems,
    async res => {
      const nodes = res.data.deliveryProfile?.profileItems.edges.map(({ node }) => node) || [];
      if (nodes.length) {
        for (const node of nodes) {
          profileItems.push(node);
        }
      }
    }
  );
  return profileItems;
}

export async function paginatedQueryShopifyDeliveryProfileSellingPlanGroups(
  id: string,
  client: ApolloClient<object>
) {
  const sellingPlanGroups: DeepExtractTypeSkipArrays<
    GetDeliveryProfileSellingPlanGroupsQuery,
    ["deliveryProfile", "sellingPlanGroups", "edges", "node"]
  >[] = [];
  await paginatedRequestStreamShopifyGraphQL(
    cursor => queryShopifyDeliveryProfileSellingPlanGroups(client, id, 5, cursor),
    res => res.data.deliveryProfile!.sellingPlanGroups,
    async res => {
      const nodes = res.data.deliveryProfile?.sellingPlanGroups.edges.map(({ node }) => node) || [];
      if (nodes.length) {
        for (const node of nodes) {
          sellingPlanGroups.push(node);
        }
      }
    }
  );
  return sellingPlanGroups;
}

export async function paginatedQueryShopifyDeliveryProfileLocationGroupZones(
  client: ApolloClient<object>,
  id: string,
  groupIndex: number
) {
  const profileLocationGroupZones: DeepExtractTypeSkipArrays<
    GetDeliveryProfileLocationGroupZonesQuery,
    ["deliveryProfile", "profileLocationGroups", "locationGroupZones", "edges", "node"]
  >[] = [];
  await paginatedRequestStreamShopifyGraphQL(
    cursor => queryShopifyDeliveryProfileLocationGroupZones(client, id, 5, cursor),
    res => res.data.deliveryProfile!.profileLocationGroups[groupIndex]?.locationGroupZones,
    async res => {
      const nodes =
        res.data.deliveryProfile?.profileLocationGroups[groupIndex]?.locationGroupZones.edges.map(
          ({ node }) => node
        ) || [];
      for (const node of nodes) {
        profileLocationGroupZones.push(node);
      }
    }
  );
  return profileLocationGroupZones;
}

export async function paginatedQueryShopifyDeliveryProfileLocationGroupLocations(
  client: ApolloClient<object>,
  id: string,
  groupIndex: number
) {
  const locationGroupLocations: LocationFragmentFragment[] = [];
  await paginatedRequestStreamShopifyGraphQL(
    cursor => queryShopifyDeliveryProfileLocationGroupLocations(client, id, 5, cursor),
    res => res.data.deliveryProfile!.profileLocationGroups[groupIndex].locationGroup.locations,
    async res => {
      const nodes =
        res.data.deliveryProfile?.profileLocationGroups[groupIndex].locationGroup.locations.edges.map(
          ({ node }) => node
        ) || [];
      for (const node of nodes) {
        locationGroupLocations.push(node);
      }
    }
  );
  return locationGroupLocations;
}

export async function paginatedQueryShopifyDeliveryProfileLocationGroupZoneMethodDefinitions(
  client: ApolloClient<object>,
  id: string,
  groupIndex: number,
  rateDefinitionCounts: number[]
) {
  const zones: DeliveryMethodDefinitionFragmentFragment[][] = [];
  // const methodDefinitions: DeliveryMethodDefinitionFragmentFragment[] = [];

  let i = 0;
  await paginatedRequestStreamShopifyGraphQL(
    cursor =>
      queryShopifyDeliveryProfileLocationGroupZoneMethodDefinitions(
        client,
        id,
        rateDefinitionCounts[i++],
        cursor
      ),
    res => res.data.deliveryProfile!.profileLocationGroups[groupIndex].locationGroupZones,
    async res => {
      const nodes =
        res.data.deliveryProfile?.profileLocationGroups[
          groupIndex
        ].locationGroupZones.edges[0].node.methodDefinitions.edges.map(({ node }) => node) || [];
      zones.push(nodes);
    }
  );

  return zones;
}

export function queryShopDeliverySettings(client: ApolloClient<object>) {
  return apolloShopifyQuery<GetShopDeliverySettingsQuery, GetShopDeliverySettingsQueryVariables>(
    {
      query: GetShopDeliverySettings,
    },
    client
  );
}

export function queryShopifyDeliveryProfileItems(
  client: ApolloClient<object>,
  id: string,
  count: number,
  cursor: string | null
) {
  return apolloShopifyQuery<GetDeliveryProfileItemsQuery, GetDeliveryProfileItemsQueryVariables>(
    {
      query: GetDeliveryProfileItems,
      fetchPolicy: "no-cache",
      variables: {
        id,
        count,
        profileItemsCursor: cursor,
      },
    },
    client
  );
}

export function queryShopifyDeliveryProfileSellingPlanGroups(
  client: ApolloClient<object>,
  id: string,
  count: number,
  cursor: string | null
) {
  return apolloShopifyQuery<
    GetDeliveryProfileSellingPlanGroupsQuery,
    GetDeliveryProfileSellingPlanGroupsQueryVariables
  >(
    {
      query: GetDeliveryProfileSellingPlanGroups,
      fetchPolicy: "no-cache",
      variables: {
        count,
        id,
        sellingPlanGroupsCursor: cursor,
      },
    },
    client
  );
}

export function queryShopifyDeliveryProfileLocationGroupZones(
  client: ApolloClient<object>,
  id: string,
  count: number,
  cursor: string | null
) {
  return apolloShopifyQuery<
    GetDeliveryProfileLocationGroupZonesQuery,
    GetDeliveryProfileLocationGroupZonesQueryVariables
  >(
    {
      query: GetDeliveryProfileLocationGroupZones,
      fetchPolicy: "no-cache",
      variables: {
        count,
        id,
        locationGroupZonesCursor: cursor,
      },
    },
    client
  );
}

export function queryShopifyDeliveryProfileLocationGroupLocations(
  client: ApolloClient<object>,
  id: string,
  count: number,
  cursor: string | null
) {
  return apolloShopifyQuery<
    GetDeliveryProfileLocationGroupLocationsQuery,
    GetDeliveryProfileLocationGroupLocationsQueryVariables
  >(
    {
      query: GetDeliveryProfileLocationGroupLocations,
      fetchPolicy: "no-cache",
      variables: {
        id,
        count,
        cursor,
      },
    },
    client
  );
}

export function queryShopifyDeliveryProfileLocationGroupZoneMethodDefinitions(
  client: ApolloClient<object>,
  id: string,
  count: number,
  cursor: string | null
) {
  return apolloShopifyQuery<
    GetDeliveryProfileLocationGroupZoneMethodDefinitionsQuery,
    GetDeliveryProfileLocationGroupZoneMethodDefinitionsQueryVariables
  >(
    {
      query: GetDeliveryProfileLocationGroupZoneMethodDefinitions,
      fetchPolicy: "no-cache",
      variables: {
        id,
        locationGroupZonesCursor: cursor,
        methodDefinitionCount: count,
      },
    },
    client
  );
}

export function mutationShopifyDeliveryProfileCreate(
  profile: DeliveryProfileInput,
  client: ApolloClient<object>
) {
  return throws(
    apolloShopifyMutation<DeliveryProfileCreateMutation, DeliveryProfileCreateMutationVariables>(
      {
        mutation: DeliveryProfileCreate,
        variables: {
          profile,
        },
      },
      client
    )
  );
}

export function mutationShopifyDeliveryProfileUpdate(
  client: ApolloClient<object>,
  id: string,
  profile: DeliveryProfileInput,
  leaveLegacyModeProfiles?: boolean
) {
  return throws(
    apolloShopifyMutation<DeliveryProfileUpdateMutation, DeliveryProfileUpdateMutationVariables>(
      {
        mutation: DeliveryProfileUpdate,
        variables: {
          id,
          profile,
          leaveLegacyModeProfiles,
        },
      },
      client
    )
  );
}

export function mutationShopifyDeliveryProfileRemove(id: string, client: ApolloClient<object>) {
  return throws(
    apolloShopifyMutation<DeliveryProfileRemoveMutation, DeliveryProfileRemoveMutationVariables>(
      {
        mutation: DeliveryProfileRemove,
        variables: {
          id,
        },
      },
      client
    )
  );
}

export function mutationShopifyDeliverySettingUpdate(
  setting: DeliverySettingInput,
  client: ApolloClient<object>
) {
  return throws(
    apolloShopifyMutation<DeliverySettingUpdateMutation, DeliverySettingUpdateMutationVariables>(
      {
        mutation: DeliverySettingUpdate,
        variables: {
          setting,
        },
      },
      client
    )
  );
}

export function mutationShopifyDeliveryShippingOriginAssign(locationId: string, client: ApolloClient<object>) {
  return throws(
    apolloShopifyMutation<DeliveryShippingOriginAssignMutation, DeliveryShippingOriginAssignMutationVariables>(
      {
        mutation: DeliveryShippingOriginAssign,
        variables: {
          locationId,
        },
      },
      client
    )
  );
}
